angular.module('enervexSalesappApp').factory('ChartUtil', function ChartUtil(_) {
	return {
		getN3Options: function(largest, chartMax, calculateFEI) {
			var series = [{
				y: 'answer',
				color: '#04486C',
				visible: true,
				thickness: '5px',
				type: 'area',
				dotSize: 5
			}]
			
			if (calculateFEI == 'true') {
				series.push({
					y: "sysCurve",
				    label: "sysCurve",
				    thickness: '2px',
				    type: 'linear',
				    color: 'black',
				    grid: true,
				    drawDots: false
				})
			}

			return {
				series: series,
				axes: {
					x: {
						key: 'x',
						max: largest,
						type: 'linear',
						ticks: [0, largest/4, 2 * largest/4, 3 * largest/4, largest],
						grid: true
					},
					y: {
						key: 'value1',
						max: chartMax,
						type: 'linear',
						ticks: 5,
						grid: true,
					}
				},
				tooltip: {
					mode: 'none'
				},
				margin: {
					left: 25,
					bottom: 25
				},
				lineMode: 'basis',
				hideOverflow: false,
				drawLegend: false,
			}
		},
		getN3PrintOptions: function(largest, series) {
			return {
				axes:{
					x: {
						key: 'x',
						type: 'linear',
						ticks: [0, largest/8, 2 * largest/8, 3 * largest/8, 4 * largest/8, 5 * largest/8, 6 * largest/8, 7 * largest/8, largest],
						grid: true,
						max: largest
					},
					y: {
						key: 'value1',
						type: 'linear',
						ticks: 8,
						grid: true
					}
				},
				tooltip: {mode: 'none'},
				margin: {
					left: 25,
					bottom: 25
				},
				series: series,
				lineMode: 'basis',
				hideOverflow: false,
				drawLegend: false,
			}
		},
		setPoints: function(fan, points, n3data, xArray, yArray, expression) {
			_.each(points, function(point) {
				if (point[1] >= 0){
					var fanPoint = {};
					fanPoint[expression] = point[1];
					fanPoint.x = point[0];
					n3data.push(fanPoint);
					if (!fan.hidden) {
						xArray.push(point[0]);
						yArray.push(point[1]);
					}
				}
			})
		}
	}
})
